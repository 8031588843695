<template>
  <div class="download-box" :class="dataObj.appClassName">
    <div class="download-content">
      <div class="todownload">
        <img v-if="dataObj.logo" :src="dataObj.logo" alt />
        <p>{{ dataObj.appName }}</p>
      </div>
      <div class="download-app">
        <div class="software-list">
          <!-- 苹果版本下载 -->
          <div class="software" v-if="dataObj.appStoreUrl && isIos">
            <a :href="dataObj.appStoreUrl">
              <img src="../assets/app-store.png" alt />
              <span></span>
              {{ isTimezoneOffset ? '苹果版本下载' : 'Apple Store' }}
            </a>
          </div>
          <!-- 谷歌版本下载 -->
          <div class="software" v-if="!isTimezoneOffset && dataObj.googlePlayUrl && !isIos">
            <a :href="dataObj.googlePlayUrl">
              <img :src="require(`../assets/google-play.png`)" alt />
              <span></span>
              {{ isTimezoneOffset ? '谷歌版本下载' : 'Google Play' }}
            </a>
          </div>
          <!-- 安卓版本下载 -->
          <div class="software" v-if="isTimezoneOffset && dataObj.chinaAppUrl && !isIos">
            <a :href="dataObj.chinaAppUrl">
              <img :src="require(`../assets/android-inland.png`)" alt />
              <span></span>
              {{ isTimezoneOffset ? '安卓版本下载' : 'Android SDK' }}
            </a>
          </div>
          <!-- 华为版本下载 -->
          <div class="software" v-if="!isTimezoneOffset && dataObj.huaweiStoreUrl">
            <a :href="dataObj.huaweiStoreUrl">
              <img :src="require(`../assets/huawei.png`)" alt />
              <span></span>
              {{ isTimezoneOffset ? '华为版本下载' : 'Huawei Store' }}
            </a>
          </div>
        </div>
      </div>
      <div class="load" v-if="loading">
        <van-loading type="spinner" />
      </div>
    </div>
    <div class="stratum" v-if="isStratum">
      <div class="stratum-message">
        <div class="message-content">
          <div class="c-text">
            <img src="../assets/stratum-icon.png" alt />
            {{ isTimezoneOffset ? '点击右上角选择浏览器打开' : 'Click the top right corner to open in the browser.' }}
          </div>
          <div class="c-btn">
            <span class="btn" @click="confirm()">{{ isTimezoneOffset ? '我知道了' : 'I know' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMarkingDetail } from "@/api/app.js";
export default {
  data() {
    return {
      key: "",
      loading: false,
      dataObj: {},
      appStoreUrl: "",
      googlePlayUrl: "",
      isTimezoneOffset: false, //是否在东8区
      imageUrl: "img/assets/bg.png",
      isStratum: false, //是否显示遮罩层
      isIos: false, //是否是ios系统
    };
  },
  methods: {
    //  新的方法
    toDownload() {
      var u = navigator.userAgent;
      var ua = u.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        this.isIos = true;
        // iOS 系统 ->  跳AppStore下载地址
        this.getMarkingDetail(this.key, (res) => {
          res.appStoreUrl && (window.location.href = res.appStoreUrl);
        });
      } else if (/android/.test(ua)) {
        this.isIos = false;
        // 安卓系统 -> 跳安卓端下载地址
        this.getMarkingDetail(this.key, (res) => {
          //如果是安卓系统在东8区不需要自动跳转
          //如果是华为或者东八区不需要自动跳转
          //暂时注释自动跳转
          // if (this.isTimezoneOffset || /huawei/.test(ua)) {
          //   return;
          // } else {
          //   window.location.href = res.googlePlayUrl;
          // }
        });
      } else if (this.IsPC()) {
        // PC端
        this.getMarkingDetail(this.key, (res) => {
          console.log("PC端");
          console.log(res);
        });
      }
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    // 获取跳转地址
    getMarkingDetail(marking, callback) {
      this.loading = true;
      let data = {
        marking,
      };
      getMarkingDetail(data).then((response) => {
        this.loading = false;
        if (!response.data) {
          this.$toast.fail("暂无数据跳转");
          return;
        }
        // 渲染背景图片

        this.dataObj = response.data;
        this.dataObj.appClassName = this.dataObj.appName
          .toLowerCase()
          .replace(/\s+/g, "-");
        callback && callback(response.data);
      });
    },
    // 确认（知道了）按钮
    confirm() {
      this.isStratum = false;
    },
  },
  mounted() {
    this.key = this.$route.params.key;
    this.toDownload();
    this.isTimezoneOffset = new Date().getTimezoneOffset() == -480; // 获取当前时区,在东8区会显示-480
    // this.isTimezoneOffset = false; // 获取当前时区,在东8区会显示-480

    // 判断是否在安卓手机上的微信浏览器中
    const isAndroidWechat =
      /Android/i.test(navigator.userAgent) &&
      /MicroMessenger/i.test(navigator.userAgent);

    if (isAndroidWechat) {
      this.isStratum = true;
    } else {
      this.isStratum = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.load {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  color: #1b1b2e;
  font-size: 60px;
}

.todownload {
  text-align: center;

  img {
    margin-top: 100px;
    width: 200px;
  }
}

.download-box {
  position: relative;

  .stratum {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);

    .stratum-message {
      position: absolute;
      right: 16px;
      top: 36px;

      .message-content {
        color: #3f3f3f;
        width: 488px;
        height: 208px;
        background-color: #fff;
        border-radius: 32px;
        padding: 32px;
        box-sizing: border-box;
        font-size: 30px;

        .c-text {
          display: flex;
          align-items: center;

          img {
            width: 32px;
            padding-right: 10px;
          }
        }

        .c-btn {
          padding-top: 32px;
          text-align: right;

          .btn {
            border: 1px solid #364389;
            color: #364389;
            border-radius: 32px;
            padding: 9px 22px;
            font-size: 30px;
            display: inline-block;
          }
        }

        &::after {
          content: "";
          position: absolute;
          right: 32px;
          top: -14px;

          border-left: transparent 14px solid;
          border-right: transparent 14px solid;
          border-bottom: #fff 16px solid;
        }
      }
    }
  }

  display: flex;
  justify-content: center;
  background: url("../assets/bg.png") no-repeat center center;

  &.fitbeing {
    background-image: url("../assets/fitbeing.png");
  }

  &.cubitt-pro {
    background-image: url("../assets/cubitt_pro.png");
  }

  background-size: cover;
  height: 100%;
}

.download-content {
  padding-top: 342px;
  width: 750px;

  .todownload {
    img {
      width: 200px;
      display: block;
      margin: 0 auto;
    }

    p {
      text-align: center;
      font-size: 52px;
      margin: 20px 0;
      font-weight: bold;
    }
  }

  .download-app {
    position: absolute;
    bottom: 84px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;

    .software-list {
      display: flex;
      justify-content: start;
      flex-direction: column;
      width: 100%;
      padding: 0 32px;
      box-sizing: border-box;

      .software {
        height: 100px;
        background-color: #fff;
        width: 100%;
        margin: 16px 0;
        border-radius: 50px;

        .software-box {
          display: flex;
          align-items: center;
          justify-items: center;
        }

        a {
          text-align: center;
          color: #1b1b2e;
          font-size: 32px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          img {
            width: 48px;
            border-radius: 10px;
            padding-right: 10px;
          }

          span {
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 20px;
            color: #3f3f3f;
          }
        }
      }
    }
  }
}

// }</style>